<template>
  <v-container class="pt-0 px-0">
    <back-toolbar :title="$t('menu.info')"></back-toolbar>
    <v-card flat>
      <v-card-text class="v-html" v-html="$t('dds.impressum')"> </v-card-text>
      <v-card-text
        >POCO version: {{ $store.state.session.buildVersion }}</v-card-text
      >
      <!--      <v-card-text v-if="isDev"-->
      <!--        >Calculated timezone: {{ `GMT +${timezone}` }}</v-card-text-->
      <!--      >-->
    </v-card>
  </v-container>
</template>

<script>
import BackToolbar from "@/components/app/common/BackToolbar";
export default {
  components: {
    BackToolbar,
  },
  computed: {
    isStaging() {
      return window.location.hostname === "poco-staging.web.app";
    },
    isDev() {
      return window.location.hostname === "localhost";
    },
    timezone() {
      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };
      return Number(new Date().getTimezoneOffset()) / -60;
    },
  },
};
</script>
